// libraries
import React from 'react';
import {ThemeProvider} from "styled-components";
// hooks & context
import { EThemeOptions, ThemeContext, getTheme } from "~/theme";
import { useColorSchemePreference } from '@algo/hooks';
import { 
    useInitializeMapLayers, 
    useInitializeUserSettings } from './global-hooks';
import { useAuth, hasAuthParams } from "react-oidc-context";
import { SiteNavContext } from './navigation/SiteNavContext';
import { DetailModalContext, IDetailModalContext, useDetailModalContext 
} from './contexts/DetailModalContext';
import { VideoboardModalContext, IVideoboardModalContext, useVideoboardModalContext 
} from './contexts/VideoboardModalContext';

import { useLocation } from 'react-router-dom';
import { useSiteNavContext } from './contexts/DataSearchOverlayContext';
import { useThemeContext } from './contexts/useThemeContext';
// components
import AppContent from './components/app-content/AppContent';
import SigningInPage from './components/pages/signing-in/SigningIn';
import AuthLoadingPage from './components/pages/auth-loading/AuthLoading';
import SigningOutPage from './components/pages/signing-out/SigningOut';
import { IHereContext, HereContext } from '@algo/herecontext';
import WeatherRadarContext, { IWeatherRadarContext, useWeatherRadarContext } from './contexts/WeatherRadarContext';

type IProps = {
  //
};

const App: React.FC<IProps> = () => {

    const location = useLocation();
    let pathName: string = location.pathname;

    // initialize the value of the theme context
    const [themeMode, setThemeMode, themeObject, setThemeObject] = useThemeContext();

    // tracks whether navigation panel is open or closed
    const [panelOpen, setPanelOpen] = 
        React.useState<boolean>(true);

    // holds information about navigation
    // used for controlling nav-based styles and logic
    const siteNavContextInit: any = useSiteNavContext(pathName, panelOpen, setPanelOpen);

    // triggers when useColorSchemePreference first runs
    const initialThemeSet = () => {

        if (themeMode !== EThemeOptions.Dark){
            setThemeMode(EThemeOptions.Dark);
            setThemeObject(getTheme(EThemeOptions.Dark));
        }

    }

    // triggers when user's prefers-color-scheme value changes
    const listenerCallback = (event: any) => {
        if (event.matches) {
            setThemeMode(EThemeOptions.Dark);
            setThemeObject(getTheme(EThemeOptions.Dark));
        }
        else {
            setThemeMode(EThemeOptions.Light);
            setThemeObject(getTheme(EThemeOptions.Light));
        }
    }
    
    // watches @media theme mode preference and updates toggles accordingly
    useColorSchemePreference(initialThemeSet, listenerCallback);

    // checks the localStorage for previously saved User Settings to update Settings store
    useInitializeUserSettings();

    // checks the localStorage for previously saved Map Layers settings to update MapLayers store
    useInitializeMapLayers();

    const auth = useAuth();

    // The point of this script is to capture the session storage'd user 
    // and silently renew the access token.
    const [hasTriedSignin, setHasTriedSignin] = React.useState<boolean>(false);

    // Make sure HERE scripts load before doing any silent sign in logic
    const hereContext: IHereContext = React.useContext(HereContext);

    React.useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin && hereContext.HEREScriptLoaded && hereContext.HARPScriptLoaded
        ) {
            // console.log("signinSilent: ATTEMPTED \nlocation.pathname: %s \nauth.isAuthenticated: %s \nauth.activeNavigator: %s \nauth.isLoading: %s \nhasTriedSignin: %s \nhereContext.HEREScriptLoaded: %s \nhereContext.HARPScriptLoaded: %s", 
            // location.pathname, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, hasTriedSignin, hereContext.HEREScriptLoaded, hereContext.HARPScriptLoaded);
            auth.signinSilent();
            
            setHasTriedSignin(true);
        }
        // else {
        //     console.log("signinSilent: NOT ATTEMPTED \nlocation.pathname: %s \nauth.isAuthenticated: %s \nauth.activeNavigator: %s \nauth.isLoading: %s \nhasTriedSignin: %s \nhereContext.HEREScriptLoaded: %s \nhereContext.HARPScriptLoaded: %s", 
        //     location.pathname, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, hasTriedSignin, hereContext.HEREScriptLoaded, hereContext.HARPScriptLoaded);
        // }
    }, [auth, hasTriedSignin, hereContext]);

    const detailModalContextInit: IDetailModalContext = useDetailModalContext();
    const videoboardModalContextInit: IVideoboardModalContext = useVideoboardModalContext();
    const weatherRadarContextInit: IWeatherRadarContext = useWeatherRadarContext();

    switch(auth.activeNavigator) {
        // case "signinSilent":
        //     return <SigningInPage />;
        case "signoutRedirect":
            return <SigningOutPage />;
    }

    // if (auth.isLoading) {
    //     return <AuthLoadingPage />
    // }

    // if (auth.error){
    //     return <div>Authentication Error.... {auth.error.message}</div>;
    // }

    return (
        <ThemeProvider theme={themeObject}>
            <ThemeContext.Provider value={themeObject}>
                <SiteNavContext.Provider value={siteNavContextInit}>
                    <DetailModalContext.Provider value={detailModalContextInit}>
                        <VideoboardModalContext.Provider value={videoboardModalContextInit}>
                            <WeatherRadarContext.Provider value={weatherRadarContextInit}>

                                    <AppContent />
                                    
                            </WeatherRadarContext.Provider>
                        </VideoboardModalContext.Provider>
                    </DetailModalContext.Provider>
                </SiteNavContext.Provider>
            </ThemeContext.Provider>
        </ThemeProvider>
    );
    
}

export default App;
