// libraries
import { getAll as getAllCities } from "~/store/algo-api/slices/cities";
import { getAll as getAllCounties } from "~/store/algo-api/slices/counties";
import { getAll as getAllCrashes } from "~/store/algo-api/slices/crashes";
import { getAll as getAllIncidents } from "~/store/algo-api/slices/incidents";
import { getAll as getAllRegionalEvents } from "~/store/algo-api/slices/regional-events";
import { getAll as getAllRoadConditions } from "~/store/algo-api/slices/road-conditions";
import { getAll as getAllRoadwork } from "~/store/algo-api/slices/roadwork";
import { filterBySelections as filterCrashes } from "~/store/algo-api/slices/crashes";
import { filterBySelections as filterIncidents } from "~/store/algo-api/slices/incidents";
import { filterBySelections as filterRegionalEvents } from "~/store/algo-api/slices/regional-events";
import { filterBySelections as filterRoadConditions } from "~/store/algo-api/slices/road-conditions";
import { filterBySelections as filterRoadwork } from "~/store/algo-api/slices/roadwork";
// hooks & context
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EAlgoLayerType } from "~/interfaces";
import React from "react";
import { minuteToMilli } from "@caps-mobile/date-time";

// dispatches store action creators for loading city and place data
// this data is used to populate the filter dropdowns for searching travel times
export const useDropdownData = () => {

    const dispatch = useDispatch();

    const cityStore = useSelector( (state: any) => state.city);
    const countyStore = useSelector( (state: any) => state.county);

    useEffect(
        () => {

            if (!countyStore.data)
                dispatch(getAllCities() as any);
            if (!cityStore.data)
                dispatch(getAllCounties() as any);

        }, [countyStore.data, cityStore.data]
    );

    return [cityStore, countyStore]
};

// filteres the content section when search selections or data stores change
export const useFilterContent = (
    searchText: string, eventTypeIndices: number[], severityIndices: number[], 
    cityIndices: number[], countyIndices: number[], regionIndices: number[],
    crashStore: any, incidentStore: any, regionalEventStore: any, 
    roadConditionStore: any, roadworkStore: any,
    cityStrings: string[], countyStrings: string[], 
    EVENT_TYPES: string[], SEVERITIES: string[], REGIONS: string[]
) => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            let reportTypes: string[] = eventTypeIndices ? eventTypeIndices.map( (index: number) => EVENT_TYPES[index]) : [];
            let severities: string[] = severityIndices ? severityIndices.map( (index: number) => SEVERITIES[index]) : [];
            let cities: string[] = cityIndices ? cityIndices.map ( (index: number) => cityStrings[index]) : [];
            let counties: string[] = countyIndices ? countyIndices.map ( (index: number) => countyStrings[index]) : [];
            let regions: string[] = regionIndices ? regionIndices.map( (index: number) => REGIONS[index]) : [];

            dispatch(filterCrashes(reportTypes, severities, cities, counties, regions, searchText) as any);
            dispatch(filterIncidents(reportTypes, severities, cities, counties, regions, searchText) as any);
            dispatch(filterRegionalEvents(reportTypes, severities, cities, counties, regions, searchText) as any);
            dispatch(filterRoadConditions(reportTypes, severities, cities, counties, regions, searchText) as any);
            dispatch(filterRoadwork(reportTypes, severities, cities, counties, regions, searchText) as any);
        }, [ 
            searchText, eventTypeIndices, severityIndices, 
            cityIndices, countyIndices, regionIndices,
            crashStore.data, incidentStore.data, regionalEventStore.data, 
            roadConditionStore.data, roadworkStore.data
        ]
    );
}

// Main hook to fetch data
export const useGetData = () => {

    const dispatch = useDispatch();

    const settingsStore: any = useSelector((state: any) => state.settings); // Getting refresh interval from settings

    // Function to fetch other layers' data
    const fetchData = () => {
        dispatch(getAllCrashes());
        dispatch(getAllIncidents());
        dispatch(getAllRegionalEvents());
        dispatch(getAllRoadConditions());
        dispatch(getAllRoadwork());
    };

    useEffect(() => {
        fetchData();

        // Get the refresh interval (default to 5 minutes if not set)
        const interval = settingsStore.refreshInterval || minuteToMilli(5);

        // Function to refresh data at regular intervals
        const refreshDataInterval = () => {
            fetchData();
        };

        // Start the interval to refresh data
        const intervalId = setInterval(refreshDataInterval, interval);

        // Cleanup the interval when component unmounts or interval changes
        return () => clearInterval(intervalId);
    }, [settingsStore.refreshInterval]);
};

// hook to get data for react viewport list
// need array to have two objects per index for styling purposes
export const useFormatData = (dataTypeSections: { [key in EAlgoLayerType]?: any[] }) => {
    const [data, setData] = React.useState<[any, any][]>([]);

    useEffect(() => {
        if (dataTypeSections) {
            const newData: [any, any][] = [];
            let previousKey: any;
            for (const [key, values] of Object.entries(dataTypeSections)) {
                if (Array.isArray(values)) {
                    for (let i = 0; i < values.length; i += 2) {
                        const firstItem = [key, values[i]];
                        const secondItem = [key, values[i + 1]];

                        // check if last key didn't end with a second item and 
                        // if so add first item of new key to last item of last key
                        // subtract 1 from i so we don't skip an object
                        if (previousKey && newData.length > 0 && newData[newData.length - 1][1][0] === previousKey && !newData[newData.length - 1][1][1]) {
                            newData[newData.length - 1][1] = firstItem;
                            i--;
                        } else {
                            newData.push([firstItem, secondItem]);
                        }
                        previousKey = key;
                    }
                }
            }
            setData(newData);
        } else {
            setData([]);
        }
    }, [JSON.stringify(dataTypeSections)]);

    return data;
};