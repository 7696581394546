// libraries
import { useEffect } from "react";
// hooks & contexts
import { useDispatch } from "react-redux";
// store
import { initializeSettings } from "./store/settings/settings";
import { initializeMapLayers  } from "./store/map-layers/map-layers";

export const useInitializeUserSettings = () => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(initializeSettings() as any);
        }, []
    )
};

export const useInitializeMapLayers = () => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(initializeMapLayers() as any);
        }, []
    )
};