// libraries
import * as React from "react";
import { nuonoe } from "@caps-mobile/common-lib";
import { useBreakpoint } from "~/library/useBreakpoint";
import { AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
// hooks & context
import { useSelector } from "react-redux";
import { useCitiesAndPlaces, useFilterContent, useGetData, useFormatData, useLoadTravelTimes } from "./hooks";
import { ThemeContext } from "~/theme";
// types & models
import { EAlgoApiObjectType } from "~/interfaces";
import { IATCity, IATPlaceDto } from "@algo/network-manager/models/v3";
// styles
import * as PageStyles from "~/components/views/page-template/Styled";
import * as SC from "~/components/views/data-search-overlay/data-type-sections/detail-section/section-types/Styled";
// components
import PageTemplate from "~/components/views/page-template/PageTemplate";
import { AlgoDropdown } from "~/components/views/algo-dropdown/AlgoDropdown";
import ApiObjectView from "~/components/views/api-object-details/ApiObjectView";
import NoResultsTemplate from "~/components/views/page-template/NoResultsTemplate";
import { getTravelColor } from "~/theme";

export type IProps = {
    //
};

export const TravelTimesPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    useGetData();
    
    const { md: isMediumMax } = useBreakpoint();
    const columnCount: number = isMediumMax ? 1 : 2;

    const travelTimeStore = useSelector( (state: any) => state["travel-time"]);
    const filteredTravelTimes = travelTimeStore.dataFilteredBySelections;
    const data: [any, any][] = useFormatData(filteredTravelTimes);

    const placeStore = useSelector( (state: any) => state.place);
    const cityStore = useSelector( (state: any) => state.city);

    const placeStrings = placeStore.data?.filter((place: IATPlaceDto) => place.type === "Interstate").map( (place: IATPlaceDto) => place.name || "??");
    const cityStrings = cityStore.data?.map( (city: IATCity) => city.name || "??");

    const [ cityIndices, setCityIndices ] = React.useState<number[]>([]);
    const [ roadwayIndices, setRoadwayIndices ] = React.useState<number[]>([]);
    const [ searchText, setSearchText ] = React.useState<string>("");

    const theme: any = React.useContext(ThemeContext);

    // load travel times if necessary
    useLoadTravelTimes();

    // load dropdown options data if necessary
    useCitiesAndPlaces(placeStore, cityStore);

    // filter content section data when selections/search changes or when data updates
    useFilterContent(
        cityIndices, roadwayIndices, cityStrings, 
        searchText, placeStore, travelTimeStore
    );

    const isLoadingContent: boolean = travelTimeStore.loading;

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 198,
    });

    const rowRenderer:React.FC<any> = ({ index, key, parent, style }) => {

        return (
            <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
            >
                {({ measure, registerChild }) => {

                    const item = data[index]
                    const data1 = item[0];
                    const data2 = item[1];

                    return (
                        // 'style' attribute required to position cell (within parent List)
                            <SC.ObjectDiv style={style} ref={(element: any) => { if (registerChild) registerChild(element); }} key={"Row-" + index} columnSize={100/columnCount}>
                                <SC.DetailsCard key={data1.id || index} columnSize={100/columnCount} travel={true} color={getTravelColor(data1, theme.mode)}>
                                    <ApiObjectView
                                        object={data1} type={EAlgoApiObjectType["travel-time"]} showChevron={false}/>
                                </SC.DetailsCard>
                                { data2 &&
                                    <SC.DetailsCard key={data2.id || index} columnSize={100/columnCount} travel={true} color={getTravelColor(data2, theme.mode)}>
                                        <ApiObjectView
                                            object={data2} type={EAlgoApiObjectType["travel-time"]} showChevron={false}/>
                                    </SC.DetailsCard>
                                }
                            </SC.ObjectDiv>
                    )
                }}
            </CellMeasurer>
        );
    }

    const handleResize = () => {
        cache.clearAll();
    };

    return (
        <PageTemplate 
            title={"Travel Times"}
            searchBar={{
                autoSubmit: true, 
                submitCallback: (newText: string) => 
                    setSearchText(newText)
            }}
            travelTimes={true}
        >

            <PageStyles.FilterRow>

                <AlgoDropdown
                    options={cityStrings}
                    placeholder={"All Cities"}
                    showPlaceholder={cityIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={(selectedIndex: number) => {
                        if(selectedIndex === -1){
                            setCityIndices([]);
                        }
                        else{
                            let curIndex: number = cityIndices.indexOf(selectedIndex);
                            let newIndices: number[] = cityIndices;
                            if (curIndex === -1) {
                                newIndices.push(selectedIndex);
                            }
                            else {
                                newIndices.splice(curIndex, 1);
                            }
                            setCityIndices([...newIndices]);
                        }
                    }}
                />

                <AlgoDropdown
                    options={placeStrings}
                    placeholder={"All Roadways"}
                    showPlaceholder={roadwayIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={(selectedIndex: number) => {
                        if(selectedIndex === -1){
                            setRoadwayIndices([]);
                        }
                        else{
                            let curIndex: number = roadwayIndices.indexOf(selectedIndex);
                            let newIndices: number[] = roadwayIndices;
                            if (curIndex === -1) {
                                newIndices.push(selectedIndex);
                            }
                            else {
                                newIndices.splice(curIndex, 1);
                            }
                            setRoadwayIndices([...newIndices]);
                        }
                    }}
                />

            </PageStyles.FilterRow>

            {   nuonoe(filteredTravelTimes) && 
                <SC.ObjectsDiv>
                    <AutoSizer onResize={handleResize}>
                        {({ height, width }: { height: number; width: number }) => { 
                            return (
                                <SC.StyledList
                                    height={height}
                                    rowCount={data.length}
                                    width={width}
                                    deferredMeasurementCache={cache}
                                    rowHeight={cache.rowHeight}
                                    rowRenderer={rowRenderer}
                                />
                            );
                        }}
                    </AutoSizer>
                </SC.ObjectsDiv>
            }

            { (!filteredTravelTimes || !filteredTravelTimes?.length || filteredTravelTimes?.length <= 0) &&
                <NoResultsTemplate loading={isLoadingContent} pageType="Travel Times" />
            }

        </PageTemplate>
    );
};

export default TravelTimesPage;