// libraries
import * as React from "react";
import { getEnumStrings } from "@caps-mobile/common-lib";
// styles
import * as SC from "./Styled";
// types & models
import { EMapTarget, IMarkerTapObject, IZoomToValue } from "~/interfaces";
// hooks & context
import { useQueryStringDetailModal } from "./hooks/query-string-hook";
import { useLocationMarker } from "./hooks/useLocationMarker";
import { useAllMarkerGroups } from "./hooks/useAllMarkerGroups";
import { useDispatch, useSelector } from "react-redux";
import { useGetData, useCursorOnFeature, useResizeMapOnNavToggle, useWindowResize } from "./hooks/general-hooks";
import { useAllPolygonGroups } from "./hooks/useAllPolygonGroups";
import { EThemeOptions, ThemeContext } from "~/theme";
import SiteNavContext from "~/navigation/SiteNavContext";
import DetailModalContext from "~/contexts/DetailModalContext";
import DataSearchOverlayContext from '../../../contexts/DataSearchOverlayContext';
import { useSetZoomAndCenter } from "./hooks/useSetZoomAndCenter";
// components
import { HereMap } from "@algo/here-maps";
import ButtonsSection from "./map-overlay-views/buttons-section/ButtonsSection";
import LegendSection from "./map-overlay-views/legend-section/LegendSection";
import SearchBarSection from "./map-overlay-views/search-bar-section/SearchBarSection";
import DataSearchOverlay from "~/components/views/data-search-overlay/DataSearchOverlay";
import TickerSection from "./map-overlay-views/ticker-section/TickerSection";
import ZoomOptionsModal from "./modals/ZoomOptionsModal";
import MapLayersModal from "./modals/MapLayersModal";
import ObjectDetailModal from "~/components/views/modal/object-detail-modal/ObjectDetailModal";
// constants
import { D_MAP_TARGET, ZOOM_TO_VALUES, NORMAL_DAY_MAP_SCHEME, NORMAL_NIGHT_MAP_SCHEME, SATELLITE_MAP_SCHEME, TERRAIN_MAP_SCHEME, USE_VECTOR_TILES, NORMAL_VECTOR_DAY_MAP_SCHEME, NORMAL_VECTOR_NIGHT_MAP_SCHEME } from "~/constants";
import { EATAleaAlertType, IATAleaAlertDto } from "@algo/network-manager/models/v3";
import { useBreakpoint } from "~/library/useBreakpoint";
import { useWeatherRadar } from "./hooks/weather-radar/useWeatherRadar";


const TARGETS: string[] = getEnumStrings(EMapTarget);

export type IProps = {
    //
};

export const MapPage: React.FC<IProps> = (props) => {

    const dispatch: any = useDispatch();
    const [ refreshData, setRefreshData ] = React.useState<boolean>(false);
    useGetData(dispatch, refreshData);
    const navContext: any = React.useContext(SiteNavContext);
    const detailModalContext: any = React.useContext(DetailModalContext);
    const theme: any = React.useContext(ThemeContext);

    const settingsStore: any = useSelector( (state: any) => state.settings);

    const [mapAndPlatform, setMapAndPlatform] = React.useState<[H.Map | undefined, H.service.Platform | undefined]>([undefined, undefined]);
    
    //  this value is only used to trigger a map zoom change. The current value will never matter. Just needs to change on click. 
    const [ zoomToggled, setZoomToggled ] = React.useState<boolean>();

    const activeWeatherStore: any = useSelector( (state: any) => state["weather-alert"]);
    const aldotMessageStore: any = useSelector( (state: any) => state["aldot-message"]);
    const aleaAlertStore: any = useSelector( (state: any) => state["alea-alert"]);
    const mapLayersStore: any = useSelector( (state: any) => state["map-layers"]);

    // zoomTo modal
    const [currentZoomTarget, setCurrentZoomTarget] = 
        React.useState<EMapTarget>(settingsStore.defaultMapTarget || EMapTarget.alabama);

    const [showZoomOptionsModal, setShowZoomOptionsModal] = React.useState<boolean>(false);

    // map layers modal
    const [showMapLayersModal, setShowMapLayersModal] = React.useState<boolean>(false);

    const markerTapHandler = (dataObject: IMarkerTapObject) => {
        detailModalContext.setModalContent(
            <ObjectDetailModal 
                object={dataObject} 
                doneCallback={() => detailModalContext.setShowModal(false)} />
        );
        detailModalContext.setShowModal(true);
    }

    const weatherPolygonTapHandler = (dataObject: IMarkerTapObject) => {
        detailModalContext.setModalContent(
            <ObjectDetailModal 
                object={dataObject} 
                doneCallback={() => detailModalContext.setShowModal(false)} />
        );
        detailModalContext.setShowModal(true);
    }

    // tracks whether the data search overlay is open or closed
    const [showOverlay, setShowOverlay] = 
        React.useState<boolean>(false);
    // tracks whether the info legend overlay is open or closed
    const [ showLegend, setShowLegend ] = 
        React.useState<boolean>(false);

    const hasWeatherAlerts: boolean = activeWeatherStore.data?.length > 0;
    const hasAldotOrAlea: boolean = 
        aldotMessageStore.data?.length > 0 ||
        aleaAlertStore.data?.filter( 
            (alert: IATAleaAlertDto) => alert.type.toLowerCase() === EATAleaAlertType.Amber.toLowerCase()
        ).length > 0;

    // number of tickers showing based on content of relevant data stores
    const activeTickerCount: number = countTickers(hasWeatherAlerts, hasAldotOrAlea);

    // holds information about the map page search overlay
    const dataSearchOverlayValue = {
        tickerCount: activeTickerCount,
        showOverlay: showOverlay,
        showLegend: showLegend,
        setShowOverlay: (newVal: boolean) => setShowOverlay(newVal),
        setShowLegend: (newVal: boolean) => setShowLegend(newVal),
        toggleShowOverlay: () => setShowOverlay(!showOverlay)
    }

    const zoomTarget: IZoomToValue = 
        ZOOM_TO_VALUES[currentZoomTarget] || D_MAP_TARGET;

    const { sm: isSmallMax } = useBreakpoint();
    const { md: isMediumMax } = useBreakpoint();

    // resizes map to fit screen and sets zoom when changing screen size
    useWindowResize(mapAndPlatform[0], window.innerWidth, window.innerHeight, zoomTarget, isMediumMax && isSmallMax);

    // The map will not automatically resize when the panel is closed.
    // This effect will wait until the animation has finished and then resize
    useResizeMapOnNavToggle(mapAndPlatform[0], navContext.panelOpen);

    // add weather radar layer to the HereMap object
    useWeatherRadar(mapAndPlatform[0]);

    // apply markers to the HereMap object
    useAllMarkerGroups(mapAndPlatform[0], markerTapHandler);

    // causes the cursor to change to pointer when mousing over markers on the map
    useCursorOnFeature(mapAndPlatform[0]);

    // apply weather polygons to the HereMap object
    useAllPolygonGroups(mapAndPlatform[0], weatherPolygonTapHandler);

    // add a user location marker to the map IF location services are active/allowed
    useLocationMarker(mapAndPlatform[0]);

    const showSpeedsValue: boolean = mapLayersStore.mapLayers["traffic-layer"];

    const mapTheme = mapLayersStore.mapScheme
        ? (mapLayersStore.mapScheme.includes("satellite"))
        ? SATELLITE_MAP_SCHEME 
        : ((mapLayersStore.mapScheme.includes("vector")) || USE_VECTOR_TILES)
        ? (theme.mode === EThemeOptions.Dark) ? NORMAL_VECTOR_NIGHT_MAP_SCHEME : NORMAL_VECTOR_DAY_MAP_SCHEME
        : (theme.mode === EThemeOptions.Dark) ? NORMAL_NIGHT_MAP_SCHEME : NORMAL_DAY_MAP_SCHEME
        : NORMAL_DAY_MAP_SCHEME;

    useSetZoomAndCenter(mapAndPlatform, zoomTarget, zoomToggled, isMediumMax && !isSmallMax);
    useQueryStringDetailModal(detailModalContext);

    // lat and lng of current location
    const [lat, setLat] = React.useState<number>();
    const [lng, setLng] = React.useState<number>();
    navigator.geolocation.getCurrentPosition(
        (position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        }
    );

    const handleRefreshLayers = () => {
        setRefreshData(prevState => !prevState);
    };

    const myLocationEnabled: boolean = lat && lng ? true : false;

    return (
        <DataSearchOverlayContext.Provider value={dataSearchOverlayValue}>

            <SC.StyledMapPage>

                <ZoomOptionsModal
                    modalOpen={showZoomOptionsModal}
                    currentSelection={TARGETS.indexOf(currentZoomTarget)}
                    selectionCallback={(newValue: EMapTarget) => { 
                        setCurrentZoomTarget(newValue); setZoomToggled( zoomToggled => !zoomToggled) 
                    }} 
                    closeCallback={() => setShowZoomOptionsModal(false)}
                    myLocationEnabled={myLocationEnabled} />

                <MapLayersModal
                    modalOpen={showMapLayersModal}
                    closeCallback={() => setShowMapLayersModal(false)} />

                {/* Contains any currently active tickers */}
                {   (activeTickerCount > 0) && 
                    <TickerSection hasWeather={hasWeatherAlerts} hasAldotOrAlea={hasAldotOrAlea} /> 
                }

                <SC.MapWrapper activeTickerCount={activeTickerCount}>
                    <HereMap
                        storeMapAndPlatform={ 
                            (map: H.Map, platform: H.service.Platform) => { setMapAndPlatform([map, platform]); } 
                        }
                        scheme={mapTheme} showTrafficLayer={showSpeedsValue}
                    />
                </SC.MapWrapper>

                {/* Contains the full-page data search overlay for finding api data objects */}
                <DataSearchOverlay />

                {/* Contains the search bar for finding specific map elements */}
                <SearchBarSection activeTickerCount={activeTickerCount}/>

                { /* Contains  the weather legend explaining weather polygon colors */ }
                { /* Should only appear when there are active weather alerts */}
                { /* Don't show on small screen size */}
                {  
                    <LegendSection 
                        radar={mapLayersStore.mapLayers["weather-radar"]} 
                        alerts={(hasWeatherAlerts && mapLayersStore.mapLayers["weather-alert"])}
                        isSmallMax={isSmallMax} />
                }

                { /* Contains the UI buttons for map layers and zoom-to*/ }
                <ButtonsSection 
                    refreshLayersCallback={() => { handleRefreshLayers() }}
                    selectLayersCallback={() => { setShowMapLayersModal(true) }}
                    zoomToCallback={() => { setShowZoomOptionsModal(true) }}
                />

            </SC.StyledMapPage> 

        </DataSearchOverlayContext.Provider>
    );

};

const countTickers = (hasWeather: boolean, hasAldotOrAlea: boolean) => {
    let count: number = 0;
    if (hasWeather) count++;
    if (hasAldotOrAlea) count++;

    return count;
};

export default MapPage;