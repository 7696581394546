// libraries
import React from "react";
import { useEffect } from "react";
import { EAlgoLayerType, IZoomToValue } from "~/interfaces";
import { getAll as getAllAldotMessages } from "~/store/algo-api/slices/aldot-messages";
import { getAll as getAllAleaAlerts } from "~/store/algo-api/slices/alea-alerts";
import { getAll as getAllCameras } from "~/store/algo-api/slices/cameras";
import { getAllGrouped as getAllCamerasGrouped } from "~/store/algo-api/slices/camera-group";
import { getAll as getAllCrashes } from "~/store/algo-api/slices/crashes";
import { getAll as getAllFerries } from "~/store/algo-api/slices/ferries";
import { getAll as getAllIncidents } from "~/store/algo-api/slices/incidents";
import { getAll as getAllMessageSigns } from "~/store/algo-api/slices/message-signs";
import { getAll as getAllRegionalEvents } from "~/store/algo-api/slices/regional-events";
import { getAll as getAllRoadConditions } from "~/store/algo-api/slices/road-conditions";
import { getAll as getAllRoadwork } from "~/store/algo-api/slices/roadwork";
import { getAll as getAllServiceAssistancePatrols } from "~/store/algo-api/slices/service-assistance-patrols";
import { getAll as getAllStateFacilities } from "~/store/algo-api/slices/state-facilities";
import { getAll as getAllTravelerInformationSystems } from "~/store/algo-api/slices/traveler-information-systems";
import { getAll as getAllWeatherAlertsLayer } from "~/store/algo-api/slices/weather-alerts-layer";
import { getAll as getAllWeatherAlertsActive } from "~/store/algo-api/slices/active-weather-alerts";
import { getKey, getLegend } from "~/store/algo-api/slices/weather-radar";
import { useSelector } from "react-redux";
import { usePrevious } from "@algo/hooks";
import { minuteToMilli } from "@caps-mobile/date-time";


// Main hook to fetch data
export const useGetData = (dispatch: any, refreshData: boolean) => {
    const mapLayersStore: any = useSelector((state: any) => state["map-layers"]);
    const weatherRadarStore = useSelector((state: any) => state["weather-radar"]);
    const settingsStore: any = useSelector((state: any) => state.settings); // Getting refresh interval from settings

    const checksum = weatherRadarStore?.percipitationLegendLastChecksum;
    const prevChecksum = usePrevious(checksum);

    const isWeatherRadarActive = mapLayersStore.mapLayers[EAlgoLayerType["weather-radar"]];

    const dispatchedLayers = React.useRef<Set<string>>(new Set()); // Tracks layers that have been dispatched

    // Function to fetch the weather radar data
    const fetchWeatherRadarData = () => {
        dispatch(getKey());
        dispatch(getLegend(true) as any);
        if (checksum !== prevChecksum) {
            dispatch(getLegend() as any);
        }
    };

    // Function to fetch other layers' data
    const fetchOtherLayerData = () => {

        //Get ticker data
        dispatch(getAllAldotMessages());
        dispatch(getAllAleaAlerts());

        Object.entries(mapLayersStore.mapLayers)
            .filter(([layerId, isActive]) => isActive && layerId !== EAlgoLayerType["weather-radar"])
            .forEach(([layerId]) => {
                // Only dispatch with "init" the first time we encounter the layer
                if (!dispatchedLayers.current.has(layerId)) {
                    dispatchedLayers.current.add(layerId); // Mark this layer as dispatched

                    switch (layerId) {
                        case EAlgoLayerType["camera-group"]:
                            dispatch(getAllCameras("init"));
                            dispatch(getAllCamerasGrouped("init"));
                            break;
                        case EAlgoLayerType.crash:
                            dispatch(getAllCrashes("init"));
                            break;
                        case EAlgoLayerType.ferry:
                            dispatch(getAllFerries("init"));
                            break;
                        case EAlgoLayerType.incident:
                            dispatch(getAllIncidents("init"));
                            break;
                        case EAlgoLayerType["message-sign"]:
                            dispatch(getAllMessageSigns("init"));
                            break;
                        case EAlgoLayerType.other511:
                            dispatch(getAllTravelerInformationSystems("init"));
                            break;
                        case EAlgoLayerType["regional-event"]:
                            dispatch(getAllRegionalEvents("init"));
                            break;
                        case EAlgoLayerType["road-condition"]:
                            dispatch(getAllRoadConditions("init"));
                            break;
                        case EAlgoLayerType.roadwork:
                            dispatch(getAllRoadwork("init"));
                            break;
                        case EAlgoLayerType["service-assistance-patrol"]:
                            dispatch(getAllServiceAssistancePatrols("init"));
                            break;
                        case EAlgoLayerType["state-facility"]:
                            dispatch(getAllStateFacilities("init"));
                            break;
                        case EAlgoLayerType["weather-alert"]:
                            dispatch(getAllWeatherAlertsLayer("init"));
                            dispatch(getAllWeatherAlertsActive("init"));
                            break;
                        default:
                            break;
                    }
                } else {
                    // Dispatch without "init" for subsequent calls
                    switch (layerId) {
                        case EAlgoLayerType["camera-group"]:
                            dispatch(getAllCameras());
                            dispatch(getAllCamerasGrouped());
                            break;
                        case EAlgoLayerType.crash:
                            dispatch(getAllCrashes());
                            break;
                        case EAlgoLayerType.ferry:
                            dispatch(getAllFerries());
                            break;
                        case EAlgoLayerType.incident:
                            dispatch(getAllIncidents());
                            break;
                        case EAlgoLayerType["message-sign"]:
                            dispatch(getAllMessageSigns());
                            break;
                        case EAlgoLayerType.other511:
                            dispatch(getAllTravelerInformationSystems());
                            break;
                        case EAlgoLayerType["regional-event"]:
                            dispatch(getAllRegionalEvents());
                            break;
                        case EAlgoLayerType["road-condition"]:
                            dispatch(getAllRoadConditions());
                            break;
                        case EAlgoLayerType.roadwork:
                            dispatch(getAllRoadwork());
                            break;
                        case EAlgoLayerType["service-assistance-patrol"]:
                            dispatch(getAllServiceAssistancePatrols());
                            break;
                        case EAlgoLayerType["state-facility"]:
                            dispatch(getAllStateFacilities());
                            break;
                        case EAlgoLayerType["weather-alert"]:
                            dispatch(getAllWeatherAlertsLayer());
                            dispatch(getAllWeatherAlertsActive());
                            break;
                        default:
                            break;
                    }
                }
            });
    };

    useEffect(() => {
        // Immediately fetch data when the component mounts
        if(isWeatherRadarActive){
            fetchWeatherRadarData();
        }
            
        fetchOtherLayerData();

        // Get the refresh interval (default to 5 minutes if not set)
        const interval = settingsStore.refreshInterval || minuteToMilli(5);

        // Function to refresh data at regular intervals
        const refreshDataInterval = () => {
            if(isWeatherRadarActive){
                fetchWeatherRadarData();
            }

            fetchOtherLayerData();
        };

        // Start the interval to refresh data
        const intervalId = setInterval(refreshDataInterval, interval);

        // Cleanup the interval when component unmounts or interval changes
        return () => clearInterval(intervalId);
    }, [settingsStore.refreshInterval, mapLayersStore.mapLayers, checksum, prevChecksum, refreshData]);
};

// causes the map view to resize after the nav panel animation
export const useResizeMapOnNavToggle = (map: H.Map | undefined, panelOpen: boolean) => {

    useEffect( 
        () => { 
            if (map)
                setTimeout( () => map.getViewPort().resize(), 500)
        }, [panelOpen]
    );

};

// causes the map view to resize and sets zoom after window resize
export const useWindowResize = (map: H.Map | undefined, width: number, height: number, zoomTarget: IZoomToValue, isMediumMax?: boolean) => {
    
    // lat and lng of current location
    const [lat, setLat] = React.useState<number>();
    const [lng, setLng] = React.useState<number>();
    navigator.geolocation.getCurrentPosition(
        (position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        }
    );
    
    useEffect( 
        () => { 
            if (map){
                setTimeout( () => {map.getViewPort().resize()
                if(zoomTarget.bounds){
                    var bbox = new H.geo.Rect(
                        isMediumMax ? zoomTarget.bounds?.top + 1 : zoomTarget.bounds?.top, 
                        zoomTarget.bounds?.left, 
                        zoomTarget.bounds?.bottom, 
                        zoomTarget.bounds?.right);
                    map.getViewModel().setLookAtData({
                        bounds: bbox
                    });
                }
                else if(zoomTarget.center){
                    map.setZoom(zoomTarget.zoom);
                    map.setCenter(zoomTarget.center);
                }
                else if(lat && lng){
                    map.setZoom(zoomTarget.zoom);
                    map.setCenter({lat: lat, lng: lng});
                }}, 0)
            }
        }, [width, height]
    );

}

// adds listener to the map that changes cursor style to pointer when
// hovering over a marker

function mapCursorListener(event: any, map: H.Map) {
    if (event.target instanceof H.map.Marker || event.target instanceof H.map.Polygon || event.target instanceof H.map.Polyline) {
        (map.getViewPort().element as any).style.cursor = 'pointer';
    } else {
        (map.getViewPort().element as any).style.cursor = 'auto';
    }
}

export const useCursorOnFeature = (map: H.Map | undefined) => {

    useEffect(
        () => {

            if (map){

                map.addEventListener('pointermove', (evt) => mapCursorListener(evt, map), false);
    
                return () => {
                    map.removeEventListener('pointermove', (evt) => mapCursorListener(evt, map), false);
                }
            }

        }, [map]
    );
};